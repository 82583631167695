import { EMPTY_DOCUMENT_ISSUANCE_MODEL } from "presentation/model/DocumentIssuance/DocumentIssuanceModel";
import { useState } from "react";
import { createContainer } from "react-tracked";

const {
    Provider: DocumentIssuanceProvider,
    useTracked: useDocumentIssuanceTracked
} = createContainer(() => useState(EMPTY_DOCUMENT_ISSUANCE_MODEL), {concurrentMode: true});
export { DocumentIssuanceProvider, useDocumentIssuanceTracked };

