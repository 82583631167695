import { IssueTypePolicy } from "constants/document/IssueTypePolicy";

export const IssueTypeDroOpts = () => {

    const getIssueTypeModel = () => {
        let items = [IssueTypePolicy.ISSUE_TYPE_EDI, IssueTypePolicy.ISSUE_TYPE_HARD_COPY, IssueTypePolicy.ISSUE_TYPE_SOFT_COPY,
            IssueTypePolicy.ISSUE_TYPE_EMAIL, IssueTypePolicy.ISSUE_TYPE_E_INVOICE, IssueTypePolicy.ISSUE_TYPE_P_PRINT
        ];

        return [
            ...(items?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [])];
    }

    return {
        getIssueTypeModel        
    }
}