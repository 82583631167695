import * as yup from 'yup';

export const commonDocumentIssuanceValidation: { [x: string]: any; } = {
  issueType: yup.string().required("Missing"),
  noOfCopy: yup.number().required("Missing"),
  noOfOriginal: yup.number().required("Missing"),
  invoiceCurrency: yup.string().required("Missing"),
  docLanguage: yup.string().required("Missing"),
  amountPrinting: yup.string().required("Missing"),
  displayBillingMsg: yup.string().required("Missing"),
  display2ndCurrency: yup.string().required("Missing"),
  reportId: yup.string().required("Missing"),
  activeInd: yup.string().required("Missing")
};

export const commonDocumentIssuanceDtlValidation: { [x: string]: any; } = {
  detailPrinting: yup.string().required("Missing")
};

export const documentIssuanceValidation: { [x: string]: any; } = {
  ...commonDocumentIssuanceValidation,
};

export const documentIssuanceDtlValidation: { [x: string]: any; } = {
  ...commonDocumentIssuanceDtlValidation,
};

export const DocumentIssuanceValidationSchema = yup.object().shape(documentIssuanceValidation);
export const DocumentIssuanceDtlValidationSchema = yup.object().shape(documentIssuanceDtlValidation);

