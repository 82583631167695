import { ChargeTypeRepoImpl } from "domain/repository/ChargeType/ChargeTypeRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { CustomerRepoImpl } from "domain/repository/Company/CustomerRepoImpl";
import { DocumentIssuanceRepoImpl } from "domain/repository/DocumentIssuance/DocumentIssuanceRepoImpl";
import { ExchangeRateRepoImpl } from "domain/repository/ExchangeRate/ExchangeRateRepoImpl";
import { ReportRepoImpl } from "domain/repository/Report/ReportRepoImpl";
import { useDocumentIssuanceTracked } from "presentation/store/DocumentIssuance/DocumentIssuanceProvider";
import { DocumentIssuanceVM } from "presentation/viewModel/DocumentIssuance/DocumentIssuanceVM";
import { useMemo } from "react";

export const useDocumentIssuanceVM = () => {
    const [, setDocumentIssuanceState] = useDocumentIssuanceTracked();
    const documentIssuanceVM = useMemo(() =>
        DocumentIssuanceVM({
            dispatch: [setDocumentIssuanceState],
            repo: DocumentIssuanceRepoImpl(),
            companyRepo: CompanyRepoImpl(),
            customerRepo: CustomerRepoImpl(),
            chargeTypeRepo: ChargeTypeRepoImpl(),
            exchangeRateRepo: ExchangeRateRepoImpl(),
            reportRepo: ReportRepoImpl()
        }), [setDocumentIssuanceState])

    return documentIssuanceVM
}

