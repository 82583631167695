import { memo, useEffect } from "react";
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import { DocumentSupportingPanel } from "./Form/DocumentSupportingPanel";
import DocumentSupportingTablePanel from "./Table/DocumentSupportingTablePanel";
import { useDocumentIssuanceTracked } from "presentation/store/DocumentIssuance/DocumentIssuanceProvider";
import { useDocumentIssuanceVM } from "presentation/hook/DocumentIssuance/useDocumentIssuanceVM";


export const DocumentIssuanceEditPanel: React.FC = () => {
    const [documentIssuanceState] = useDocumentIssuanceTracked();
    const documentIssuanceVM = useDocumentIssuanceVM();
    const { isSliderOpen } = documentIssuanceState.detailState;
    const documentIssuanceEty = documentIssuanceState.currentSelectedRow;

    useEffect(() => {
        documentIssuanceEty?.id && documentIssuanceVM.onSearchDocSupporting(documentIssuanceEty.id)
    }, [documentIssuanceEty.id, documentIssuanceVM])
    
        return <div className='main-comp-wrapper'>
                <SliderPanel
                    isOpen={isSliderOpen}
                    leftSectionWidth={'60%'}
                    rightSectionWidth={'40%'}
                    draggable={true}
                    leftChildren={<DocumentSupportingTablePanel/>}
                    rightChildren={<DocumentSupportingPanel/>} />
            </div>
    }
    
export default memo(DocumentIssuanceEditPanel);