import { AmountPrintingPolicy } from "constants/document/AmountPrintingPolicy";

export const AmountPrintingDroOpts = () => {

    const getAmountPrintingModel = () => {
        let items = [AmountPrintingPolicy.NORMAL, AmountPrintingPolicy.IN_XXX, AmountPrintingPolicy.TOTAL_AMOUNT_ONLY];

        return [
            ...(items?.map(value => ({
                dropdownLabel: value,
                tagLabel: value,
                value: value
              }))?? [])];
    }

    return {
        getAmountPrintingModel        
    }
}