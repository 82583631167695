import { Permission } from "presentation/constant/ANAInfo/PermissionName";
import { DocumentIssuanceProvider } from "presentation/store/DocumentIssuance/DocumentIssuanceProvider";
import { ANAInfoWrapper } from "presentation/view/components/ANAInfo/ANAInfoWrapper";
import { MessageBarWrapper } from "presentation/view/components/MessageBarWrapper";
import { DocumentIssuanceMasterView } from "presentation/view/section/DocumentIssuance/DocumentIssuanceMasterView";
import { GridStyles } from "veronica-ui-component/dist/component/core/styled/table.styled";

const DocumentIssuanceMaintenanceCont = () => {
    return <ANAInfoWrapper permission={Permission.DOCUMENT_ISSUANCE_MAINTENANCE}>
        <MessageBarWrapper>
            <DocumentIssuanceProvider>
                <GridStyles/>
                <DocumentIssuanceMasterView/>
            </DocumentIssuanceProvider>
        </MessageBarWrapper>
    </ANAInfoWrapper>
}

export default DocumentIssuanceMaintenanceCont;