
import { ResponseEntity } from "domain/entity/Common/ResponseEntity";
import { axiosDeleteData, axiosGetData, axiosPostData, axiosPutData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { DocumentIssuanceRepository } from "./DocumentIssuanceRepo";
import { DocumentIssuanceEntity } from "domain/entity/Document/DocumentIssuanceEntity";
import { DocumentSupportingEntity } from "domain/entity/Document/DocumentSupportingEntity";

/**
 * For template
 * @returns 
 */
export const DocumentIssuanceRepoImpl = (): DocumentIssuanceRepository => {
    const url = '/v1/documentIssuance';

    const getEntities = async() : Promise<DocumentIssuanceEntity[]> => {
        return await axiosGetData(referenceAxiosInstance, url, []).then(res => {
            return res.data
        }).catch(err => {
            return [];
        });
        
    }

    const getEntityByKey = async(key: string): Promise<DocumentIssuanceEntity> => {
        return await axiosGetData(referenceAxiosInstance, `${url}/${key}`, []).then(res => {
            return res.data;
        }).catch(err => {
            return null;
        });
    }

    const createEntity = async(newData: DocumentIssuanceEntity) : Promise<ResponseEntity> => {
        const newUrl = url + '/add';
        return await axiosPostData(referenceAxiosInstance, `${newUrl}`, newData);
    }

    const updateEntity = async(updatedData: DocumentIssuanceEntity): Promise<ResponseEntity> => {
        const newUrl = url + '/update';
        return await axiosPutData(referenceAxiosInstance, `${newUrl}`, updatedData);
    }

    const deleteEntity= async(key: string): Promise<ResponseEntity> => {
        return await axiosDeleteData(referenceAxiosInstance, `${url}`, key);
    }

    const applyEntity = async(applyData: DocumentIssuanceEntity[]) : Promise<ResponseEntity> => {
        const newUrl = url + '/apply';
        return await axiosPostData(referenceAxiosInstance, `${newUrl}`, applyData);
    }

    const searchDocSupporting = async(docIssuanceId: number) : Promise<DocumentSupportingEntity[]> => {
        const newUrl = url + "/getDocSupporting";
        
        return await axiosGetData(referenceAxiosInstance, `${newUrl}/${docIssuanceId}`, []).then(res => {
            return res.data
        }).catch(err => {
            return [];
        });
        
    }

    return {
        getEntities: getEntities,
        getEntityByKey: getEntityByKey,
        createEntity: createEntity,
        updateEntity: updateEntity,
        deleteEntity: deleteEntity,
        applyEntity: applyEntity,
        searchDocSupporting: searchDocSupporting,
    }
}