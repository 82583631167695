import { DocumentIssuanceEntity, EMPTY_DOCUMENT_ISSUANCE_ENTITY } from "domain/entity/Document/DocumentIssuanceEntity";
import { DocumentSupportingEntity, EMPTY_DOCUMENT_SUPPORTING_ENTITY } from "domain/entity/Document/DocumentSupportingEntity";
import { ChargeTypeRepository } from "domain/repository/ChargeType/ChargeTypeRepo";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { CustomerRepository } from "domain/repository/Company/CustomerRepo";
import { DocumentIssuanceRepository } from "domain/repository/DocumentIssuance/DocumentIssuanceRepo";
import { ExchangeRateRepository } from "domain/repository/ExchangeRate/ExchangeRateRepo";
import { ReportRepository } from "domain/repository/Report/ReportRepo";
import _ from "lodash";
import { DocumentIssuanceDtlValidationSchema, DocumentIssuanceValidationSchema } from "presentation/constant/DocumentIssuance/DocumentIssuanceValidationSchema";
import { ActiveIndDroOpts } from "presentation/constant/DropDownOptions/Common/ActiveIndDroOpts";
import { AmountPrintingDroOpts } from "presentation/constant/DropDownOptions/Document/AmountPrintingDroOpts";
import { DocumentTypeDroOpts } from "presentation/constant/DropDownOptions/Document/DocumentTypeDroOpts";
import { IssueTypeDroOpts } from "presentation/constant/DropDownOptions/Document/IssueTypeDroOpts";
import { Validation } from "presentation/constant/Validation";
import { DocumentIssuanceModel } from "presentation/model/DocumentIssuance/DocumentIssuanceModel";
import { DropdownProps } from "presentation/model/DropdownProps";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IFieldValue } from "veronica-ui-component/dist/component/core";
import BaseViewModel from "../BaseViewModel";

interface DocumentIssuanceVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<DocumentIssuanceModel>> | ((value: SetStateAction<DocumentIssuanceModel>) => void),
    ],
    repo: DocumentIssuanceRepository,
    companyRepo: CompanyRepository,
    customerRepo: CustomerRepository,
    chargeTypeRepo: ChargeTypeRepository,
    exchangeRateRepo: ExchangeRateRepository,
    reportRepo: ReportRepository
}

export const DocumentIssuanceVM = ({ dispatch, repo, companyRepo, customerRepo, chargeTypeRepo, exchangeRateRepo, reportRepo }: DocumentIssuanceVMProps) => {
    const [docIssuanceDispatch] = dispatch;

    const onShowLoading = () => {
        docIssuanceDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        docIssuanceDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }

    const updateSelectedRows = async (rows: DocumentIssuanceEntity[]) => {

        docIssuanceDispatch(prevState => {
            return {
                ...prevState,
                //tableData: [...allRows],
                selectedRows: rows
            }
        })
    }

    /*const handleMoveAfter = async (rows: DocumentIssuanceEntity[], targetItem: DocumentIssuanceEntity) => {
        let targetTarCodeItems: any[] = [];        

        tariffCodeDispatch(prevState => {  
            const allDatas = prevState.tariffCodeEntityList;

            const tempTarCodeItems = allDatas.filter(itemData => !rows.some(row => itemData.id === row.id)); 

            let targetIndex:number = -1;
            for (let i = 0; i < tempTarCodeItems.length; i++) {
                const tempRow = tempTarCodeItems[i];

                if(targetItem.seq === tempRow.seq){
                    targetIndex = i;
                }
            }               
            
            targetTarCodeItems = [...tempTarCodeItems.slice(0, targetIndex + 1), ...rows, ...tempTarCodeItems.slice(targetIndex + 1, tempTarCodeItems.length)];                  
            return {
                ...prevState,
                tariffCodeEntityList: [],
            } 
        });
    }*/

    const onRowDoubleClick = (entity: DocumentIssuanceEntity) => {
        docIssuanceDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: false,
                isShowDetail: true,
                currentSelectedRow: {
                    ...entity
                },
                masterState: {
                    ...prevState.masterState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: true,
                    isSaveClicked: false,
                    isSliderOpen: true,
                    allFormState: {}
                },
                detailState: {
                    ...prevState.detailState,
                    isTabularDataActive: false,
                    isAdd: false,
                    isRead: true,
                    isEditable: false,
                    isSliderOpen: false,
                    isSelected: false,
                    allFormState: {}
                }
            }
        })
    }

    const onRowDrag = (rows: DocumentIssuanceEntity[]) => {
        /*tariffCodeDispatch(prevState =>{ 
            const prevRows = prevState.tariffCodeEntityList;
            const toChangedRows = rows.map((e, index) => ({...e,tempSeq: prevRows[index].seq})).filter(e=>e.tempSeq!==e.seq);            
            return ({
                ...prevState,
                tariffCodeEntityList: rows,
                updatedRows: toChangedRows,
        })})*/
    }

    const loadDropdownOption = async () => {
        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let newCompanies = companies?.filter(company => company.companyType !== 'MASTER' && company.companyType !== 'ALLIANCE');

                let companyDropdownOptions = newCompanies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.id.toString(),
                    value: company.companyCode,
                })) ?? []
                companyDropdownOptions = _.orderBy(companyDropdownOptions, "dropdownLabel");

                docIssuanceDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        billToCompanyDropdownOptions: companyDropdownOptions
                    }
                }))
            }
        );

        await customerRepo.getCustomerActive().then(
            customers => {
                const sortCustomers = _.orderBy(customers, ["customerCode"]);
                let customerCodeDropdownOptions: DropdownProps[] = [];
                for (let i = 0; i < sortCustomers.length; i++) {
                    if (sortCustomers[i].id) {
                        customerCodeDropdownOptions.push({
                            dropdownLabel: sortCustomers[i].customerCode,
                            tagLabel: sortCustomers[i].id.toString(),
                            value: sortCustomers[i].customerCode
                        });
                    }
                }

                docIssuanceDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        customerCodeDropdownOptions: customerCodeDropdownOptions,
                    }
                }))
            }
        );

        await chargeTypeRepo.getAllChargeTypesForCombobox().then(
            chargeTypes => {
                let newChargeTypes = _.orderBy(chargeTypes, ["chargeType", "subChargeType"]);
                let chargeTypeDropdownOptions: DropdownProps[] = [];

                docIssuanceDispatch(prevState => {
                    for (let i = 0; i < newChargeTypes.length; i++) {
                        const chgTypeEty = newChargeTypes[i];
                        const tempChargeType = prevState.currentSelectedRow.chargeType;
                        const chgTypeExisted = chargeTypeDropdownOptions.find(chgType =>
                            chgType.value === chgTypeEty.chargeType);

                        if (!chgTypeExisted) {
                            if (tempChargeType) {
                                if (tempChargeType === chgTypeEty.chargeType) {
                                    chargeTypeDropdownOptions.push({
                                        dropdownLabel: chgTypeEty.chargeType,
                                        tagLabel: chgTypeEty.chargeCategory as string,
                                        value: chgTypeEty.chargeType
                                    })

                                    break;
                                }
                            } else {
                                chargeTypeDropdownOptions.push({
                                    dropdownLabel: chgTypeEty.chargeType,
                                    tagLabel: chgTypeEty.chargeCategory as string,
                                    value: chgTypeEty.chargeType
                                })
                            }
                        }
                    }

                    return {
                        ...prevState,
                        dynamicOptions: {
                            ...prevState.dynamicOptions,
                            chargeTypeDropdownOptions: chargeTypeDropdownOptions
                        }
                    }
                })
            }
        );

        await exchangeRateRepo.getAllCurrencies().then(
            currencies => {
                let currencyDropdownOptions = currencies?.map((currency) => ({
                    dropdownLabel: currency.currencyCode,
                    tagLabel: currency.currencyCode,
                    value: currency.currencyCode,
                })) ?? []

                docIssuanceDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        invoiceCurrencyDropdownOptions: currencyDropdownOptions,
                    }
                }))
            }
        );

        await reportRepo.getReport().then(
            reports => {
                let invReports = reports?.filter(report => report.reportCategory === "INVOICE");
                let suppReports = reports?.filter(report => report.reportCategory === "SUPP");

                let invReportDropdownOptions = invReports?.map((report) => ({
                    dropdownLabel: report.defaultReportName as string,
                    tagLabel: report.reportId as string,
                    value: report.defaultReportName as string,
                })) ?? []
                invReportDropdownOptions = _.orderBy(invReportDropdownOptions, "dropdownLabel");

                let suppReportDropdownOptions: { [key: string]: DropdownProps[] } = {};
                suppReports.forEach(suppReport => {
                    let chgTypeKey: string = "";
                    if (suppReport.chargeType) {
                        const chgTypes = suppReport.chargeType.split(",");
                        chgTypes.forEach(chargeType => {
                            if (!suppReportDropdownOptions[chargeType ?? ""]) {
                                suppReportDropdownOptions[chargeType ?? ""] = [];
                            }

                            suppReportDropdownOptions[chargeType ?? ""].push({
                                dropdownLabel: suppReport.defaultReportName as string,
                                tagLabel: suppReport.reportId as string,
                                value: suppReport.defaultReportName as string,
                            })
                        })
                    } else {
                        if (!suppReportDropdownOptions[chgTypeKey ?? ""]) {
                            suppReportDropdownOptions[chgTypeKey ?? ""] = [];
                        }

                        suppReportDropdownOptions[chgTypeKey ?? ""].push({
                            dropdownLabel: suppReport.defaultReportName as string,
                            tagLabel: suppReport.reportId as string,
                            value: suppReport.defaultReportName as string,
                        })
                    }
                });

                docIssuanceDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        reportTemplateDropdownOptions: invReportDropdownOptions,
                        supportingDocDropdownOptions: suppReportDropdownOptions
                    }
                }))
            }
        );

        let dtlPrintDropdownOptions: { [key: string]: DropdownProps[] } = {};
        dtlPrintDropdownOptions["EMPTY"] = [{ dropdownLabel: "N/A", tagLabel: "N/A", value: "N/A", }];
        dtlPrintDropdownOptions["NON-EMPTY"] = ActiveIndDroOpts().getDroOptsModel();

        let languageDropdownOptions: DropdownProps[] = [];
        languageDropdownOptions.push({
            dropdownLabel: "en",
            tagLabel: "en",
            value: "en",
        });
        languageDropdownOptions.push({
            dropdownLabel: "es",
            tagLabel: "es",
            value: "es",
        });

        docIssuanceDispatch(prevState => ({
            ...prevState,
            dynamicOptions: {
                ...prevState.dynamicOptions,
                docTypeDropdownOptions: DocumentTypeDroOpts().getDocumentTypeModel(),
                indDropdownOptions: ActiveIndDroOpts().getDroOptsModel(),
                amountPrintingDropdownOptions: AmountPrintingDroOpts().getAmountPrintingModel(),
                issueTypeDropdownOptions: IssueTypeDroOpts().getIssueTypeModel(),
                detailPrintingDropdownOptions: dtlPrintDropdownOptions,
                languageDropdownOptions: languageDropdownOptions
            }
        }))
    }

    const searchDocIssuance = async () => {
        await repo.getEntities().then((data) => {
            docIssuanceDispatch(prevState => {
                return {
                    ...prevState,
                    isBackMaster: false,
                    selectedRows: [],
                    tableData: data,
                    currentSelectedRow: { ...EMPTY_DOCUMENT_ISSUANCE_ENTITY }
                }
            })
        }).catch((error) => {
            return [];
        })
    }

    const onFieldChange = (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        return docIssuanceDispatch(prevState => {
            val = getValForSpecialField(fieldKey, val, prevState);

            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...prevState.masterState.editingEntity,
                        [fieldKey]: val
                    }
                }
            }
        });
    }

    const getValForSpecialField = (fieldKey: string, fieldVal: any, prevState: DocumentIssuanceModel) => {
        if (fieldKey === "issueType") {
            fieldVal = fieldVal?.toString().replaceAll(",", ";");
        } else if (fieldKey === "reportId") {
            fieldVal = prevState.dynamicOptions.reportTemplateDropdownOptions?.find(item => item.value === fieldVal)?.tagLabel ?? "";
        } else if (fieldKey === "customerId") {
            fieldVal = Number(prevState.dynamicOptions.customerCodeDropdownOptions?.find(item => item.value === fieldVal)?.tagLabel ?? "");
        } else if (fieldKey === "billToCompanyId") {
            fieldVal = Number(prevState.dynamicOptions.billToCompanyDropdownOptions?.find(item => item.value === fieldVal)?.tagLabel ?? "");
        }

        return fieldVal;
    }

    const onInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {

        /*const val =  e.target.value;
        tariffCodeDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: fieldName === '' ? val.toUpperCase().replace(/\s+/g, '') : val,
            }
        }))*/
    };


    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        /*tariffCodeDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
            }
        }));*/
    };

    const onDropdownChange = (e: any, fieldName: string) => {
        /*tariffCodeDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [fieldName]: e?.value ?? (_.isEmpty(e) ? 
                    (fieldName === "tariffCodeList" ? [] : '') : e),
            }
        }))*/
    }


    const onDateRangeChange = (startDate: any, endDate: any, dateFields: { startField: string, endField: string }) => {
        /*tariffCodeDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))*/
    }


    const onAddClick = () => {
        const createDocIssuanceEty = EMPTY_DOCUMENT_ISSUANCE_ENTITY;
        createDocIssuanceEty.noOfCopy = 0;
        createDocIssuanceEty.noOfOriginal = 1;
        createDocIssuanceEty.amountPrinting = "Normal";
        createDocIssuanceEty.displayBillingMsg = "Y";
        createDocIssuanceEty.display2ndCurrency = "Y";
        createDocIssuanceEty.activeInd = "Y";
        createDocIssuanceEty.asteriskLabel = "Y";

        docIssuanceDispatch(prevState => ({
            ...prevState,
            //isShowDetail: true,
            currentSelectedRow: createDocIssuanceEty,
            masterState: {
                ...prevState.masterState,
                isTabularDataActive: false,
                isAdd: true,
                isRead: false,
                isEditable: false,
                isSaveClicked: false,
                isSliderOpen: true,
                allFormState: {},
                editingEntity: createDocIssuanceEty,
            },
            detailState: {
                ...prevState.detailState,
                isTabularDataActive: false,
                isAdd: false,
                isRead: false,
                isEditable: false,
                isSliderOpen: false,
                isSelected: false,
                allFormState: {}
            }
        }))
    };

    const onApply = async (updatedRows: DocumentIssuanceEntity[]) => {
        return await repo.applyEntity(updatedRows);
    }

    const onResetClick = async () => {
        docIssuanceDispatch(prevState => {
            let tempDocIssuanceEty: DocumentIssuanceEntity = EMPTY_DOCUMENT_ISSUANCE_ENTITY;
            if (!prevState.masterState.isAdd) {
                tempDocIssuanceEty = prevState.currentSelectedRow
            }

            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    editingEntity: {
                        ...tempDocIssuanceEty,
                    },
                }
            }
        })
    }

    const onTempSaveDocSupporting = async (currentDocSupporting: DocumentSupportingEntity) => {
        const valResult = await Validation(DocumentIssuanceDtlValidationSchema).ValidateFormOnly(currentDocSupporting);

        //let msg = 'Error when save data.'        
        let validatedResult: { [x: string]: string } = {};
        if (valResult) {
            validatedResult = { ...validatedResult, ...valResult, mandatoryCheckFail: 'Please input the missing value.' };
            docIssuanceDispatch(prevState => {
                return {
                    ...prevState,
                    detailState: {
                        ...prevState.detailState,
                        allFormState: {
                            ...validatedResult
                        },
                    }
                }
            });
            return validatedResult;
        } else {
            return docIssuanceDispatch(prevState => {
                let tempTableData = prevState.detailState.docSupportingList;
                let docIssuanceEty = prevState.masterState.editingEntity;

                if (!tempTableData) {
                    tempTableData = [];
                }

                const tempData = tempTableData.find(docSupporting =>
                    docSupporting.chargeType === currentDocSupporting.chargeType);
                if (tempData) {
                    tempData.detailPrinting = currentDocSupporting.detailPrinting;
                    tempData.supportingDoc = currentDocSupporting.supportingDoc;
                } else {
                    tempTableData.push(currentDocSupporting);
                }
                docIssuanceEty.supportingDetails = tempTableData;

                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        editingEntity: docIssuanceEty
                    },
                    detailState: {
                        ...prevState.detailState,
                        docSupportingList: tempTableData,
                        selectedRows: [],
                        currentRow: EMPTY_DOCUMENT_SUPPORTING_ENTITY,
                        isShowDetailInfo: false,
                        isAdd: false,
                        isEditable: false,
                        isSliderOpen: false,
                    }
                }
            })
        }
    }

    const onSave = async (currentDocIssuanceEty: DocumentIssuanceEntity, isAdd: boolean) => {
        let valHdrResult = await Validation(DocumentIssuanceValidationSchema).ValidateFormOnly(currentDocIssuanceEty);
        if (!(currentDocIssuanceEty.billToCompanyId || currentDocIssuanceEty.customerId)) {
            valHdrResult = { ...valHdrResult, billToCompanyId: "Missing", customerId: "Missing" }
        }

        if (valHdrResult) {
            let validatedTariffResult: { [x: string]: string } = {};
            if (valHdrResult) {
                validatedTariffResult = { ...validatedTariffResult, ...valHdrResult, mandatoryCheckFail: 'Please input the missing value.' };
            }

            docIssuanceDispatch(prevState => {
                return {
                    ...prevState,
                    masterState: {
                        ...prevState.masterState,
                        allFormState: {
                            ...validatedTariffResult
                        },

                    }
                }
            });
            //console.log(validatedTariffResult);
            return validatedTariffResult;
        } else {

            let res = null;
            if (isAdd) {
                res = await repo.createEntity(currentDocIssuanceEty);
            } else {
                res = await repo.updateEntity(currentDocIssuanceEty);
            }

            if (res.success) {
                onCloseClick();
            } else {
                return res.data;
            }
        }
    }

    const onCloseClick = () => {
        docIssuanceDispatch(prevState => {
            return {
                ...prevState,
                isShowDetail: false,
                isBackMaster: true,
                selectedRows: [],
                currentSelectedRow: { ...EMPTY_DOCUMENT_ISSUANCE_ENTITY },
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isTabularDataActive: true,
                    editingEntity: EMPTY_DOCUMENT_ISSUANCE_ENTITY,
                },
                detailState: {
                    ...prevState.detailState,
                    isAdd: false,
                    isEditable: false,
                    isRead: true,
                    isTabularDataActive: true,
                    docSupportingList: [],
                    selectedRows: [],
                    currentRow: EMPTY_DOCUMENT_SUPPORTING_ENTITY
                }
            }
        });
    }

    const onSaveClicked = () => {
        docIssuanceDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }


    const onEdit = (currentDocIssuanceEty: DocumentIssuanceEntity) => {
        docIssuanceDispatch(prevState => {
            return {
                ...prevState,
                masterState: {
                    ...prevState.masterState,
                    isAdd: false,
                    isRead: false,
                    isEditable: true,

                    editingEntity: {
                        ...currentDocIssuanceEty,
                    }
                },
                detailState: {
                    ...prevState.detailState,
                    isRead: false
                }
            }
        })
    }

    /*const onRadioChange = (e: any, fieldName: string) => {
        if(e.checked){
            return tariffCodeDispatch(prevState => {
                return {
                    ...prevState,
                    masterState:{
                        ...prevState.masterState,
                        editingTariffCodeEntity: {
                            ...prevState.masterState.editingTariffCodeEntity,
                            [fieldName]: e.key,
                        },
                    } 
                    }
                             
                })
        }
    }*/

    const onCheckboxChange = (checked: boolean, fieldName: string) => {
        docIssuanceDispatch(prevState => ({
            ...prevState,
            masterState: {
                ...prevState.masterState,
                editingEntity: {
                    ...prevState.masterState.editingEntity,
                    [fieldName]: checked ? "Y" : "N",
                }
            }
        }))
    }

    const onDocSupportingCancel = () => {
        docIssuanceDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    currentRow: EMPTY_DOCUMENT_SUPPORTING_ENTITY,
                    isAdd: false,
                    isEditable: false,
                    //isRead: false,
                    isSliderOpen: false,
                    allFormState: {}
                }
            }
        })
    }

    const onDocSupportingSaveClicked = () => {
        docIssuanceDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    isSaveClicked: true,
                    allFormState: {},
                }
            }
        })
    }

    const onSupportingFieldChange = async (fieldKey: string, fieldValue: IFieldValue, fFullValue?: any) => {
        let val: any = fieldValue;
        if (_.isArray(val)) {
            val = _.uniq(val?.map((item: any) => item.value || item.key));
        }

        if (val && fieldKey === "supportingDoc") {
            val = val.join(",");
        }

        if (fieldKey === "chargeType") {
            docIssuanceDispatch(prevState => {
                return {
                    ...prevState,
                    detailState: {
                        ...prevState.detailState,
                        currentRow: {
                            ...prevState.detailState.currentRow,
                            detailPrinting: null,
                            supportingDoc: null
                        },
                        allFormState: {
                            ...prevState.detailState.allFormState,
                            detailPrinting: '',
                            supportingDoc: ''
                        },
                    }
                }
            })
        }

        docIssuanceDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    currentRow: {
                        ...prevState.detailState.currentRow,
                        [fieldKey]: val
                    },
                    allFormState: {
                        ...prevState.detailState.allFormState,
                        [fieldKey]: '',
                    },
                }
            }
        })
    }

    const updateSelectedSupportingRows = async (rows: any[]) => {
        docIssuanceDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    selectedRows: rows,
                }
            }
        })
    }

    const onSupportingRowClick = (row: DocumentSupportingEntity) => {
        docIssuanceDispatch(prevState => {
            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    currentRow: row,
                    isSliderOpen: true,
                    //isRead: !prevState.masterState.isAdd, 
                    isEditable: true,
                }
            }
        });
    }

    const onSupportingAdd = () => {
        docIssuanceDispatch(prevState => {

            return {
                ...prevState,
                detailState: {
                    ...prevState.detailState,
                    currentRow: EMPTY_DOCUMENT_SUPPORTING_ENTITY,
                    isAdd: true,
                    isSliderOpen: true,
                    isEditable: false,
                    isRead: false,
                    isSaveClicked: false,
                }
            }
        })
    }

    const onSearchDocSupporting = async (docIssuanceId: number) => {

        await repo.searchDocSupporting(docIssuanceId).then((data) => {
            docIssuanceDispatch(prevState => {
                return {
                    ...prevState,
                    detailState: {
                        ...prevState.detailState,
                        docSupportingList: data,
                        selectedRows: [],
                        currentRow: EMPTY_DOCUMENT_SUPPORTING_ENTITY
                    }
                }
            })
        }).catch((error) => {
            return [];
        })
    }

    const onComponentChanged = (componentCode: string | null) => {
        /*tariffCodeDispatch(prevState => {
            const valCode = !!!componentCode ? '' : prevState.dynamicOptions.tariffComponentValueDropdownOptions[componentCode][0].value;
            return {
                ...prevState,
                componentState:{
                    ...prevState.componentState,
                    currentSelectItem: {
                        ...prevState.componentState.currentSelectItem,
                        tariffCompValueCode: valCode,
                    }
                }
            }
        })*/
    }

    return {
        loadDropdownOption: loadDropdownOption,
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onRowDoubleClick: onRowDoubleClick,
        onRowDrag: onRowDrag,
        updateSelectedRows: updateSelectedRows,
        searchDocIssuance: searchDocIssuance,
        onInputTextChange: onInputTextChange,
        onMultipleDropdownChange: onMultipleDropdownChange,
        onDropdownChange: onDropdownChange,
        onDateRangeChange: onDateRangeChange,
        onAddClick: onAddClick,
        onApply: onApply,
        onResetClick: onResetClick,
        onTempSaveDocSupporting: onTempSaveDocSupporting,
        onSave: onSave,
        onCloseClick: onCloseClick,
        onSaveClicked: onSaveClicked,
        onEdit: onEdit,
        onFieldChange: onFieldChange,
        onCheckboxChange: onCheckboxChange,
        onDocSupportingCancel: onDocSupportingCancel,
        onDocSupportingSaveClicked: onDocSupportingSaveClicked,
        onSupportingFieldChange: onSupportingFieldChange,
        updateSelectedSupportingRows: updateSelectedSupportingRows,
        onSupportingRowClick: onSupportingRowClick,
        onSupportingAdd: onSupportingAdd,
        onSearchDocSupporting: onSearchDocSupporting,
        onComponentChanged: onComponentChanged,
    }
}