import { SelectionChangedEvent } from "ag-grid-community";
import { DocumentIssuanceEntity } from "domain/entity/Document/DocumentIssuanceEntity";
import _ from "lodash";
import { INITIAL_DOCUMENT_ISSUANCE_COL_DEF, transferRowData } from "presentation/constant/DocumentIssuance/DocumentIssuanceColumnDefinition";
import { DocumentIssuanceConstant } from "presentation/constant/DocumentIssuance/DocumentIssuanceConstant";
import { useDocumentIssuanceVM } from "presentation/hook/DocumentIssuance/useDocumentIssuanceVM";
import { useMessageBarVM } from "presentation/hook/useMessageBar";
import { useANAInfoTracked } from "presentation/store/ANAInfo";

import { useDocumentIssuanceTracked } from "presentation/store/DocumentIssuance/DocumentIssuanceProvider";
import { changeCursor } from "presentation/view/components/NbisRightClickMenu/RightClickMenuAssist";
import RightClickMenuWithMoveAfter from "presentation/view/components/NbisRightClickMenu/RightClickMenuWithMoveAfter";
import NbisTable from "presentation/view/components/TableWrapper/NbisTable";
import { TableWrapper } from "presentation/view/components/TableWrapper/TableWrapper";
import { createRef, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "veronica-ui-component/dist/component/core";


const DocumentIssuanceTablePanel: React.FC = () => {
    const [documentIssuanceState] = useDocumentIssuanceTracked();
    const [anainfoState] = useANAInfoTracked();
    const documentIssuanceVM = useDocumentIssuanceVM();
    const messageBarVM = useMessageBarVM();

    const gridRef: any = useRef(null);
    const rightClickRef: any = createRef();
    const { allowUpdate } = anainfoState;

    const [onTableSelectionClicked, setOnTableSelectionClicked] = useState<boolean>(false);
    // for right click menu with move after begin
    const [showMoveCursor, setShowMoveCursor] = useState<boolean>(false);
    const [allRows, setAllRows] = useState<DocumentIssuanceEntity[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [initialAllRows, setInitialAllRows] = useState<boolean>(true);

    const handleSelectionChange = useCallback((e: SelectionChangedEvent) => {
        setOnTableSelectionClicked(true);
        const selectedRows = e.api.getSelectedRows();

        documentIssuanceVM.updateSelectedRows(selectedRows);
    }, [documentIssuanceVM])

    const getShowMoveCursor = useCallback(() => {
        return showMoveCursor;
    }, [showMoveCursor]);

    useEffect(() => {
        if (!onTableSelectionClicked) return;
        gridRef.current?.gridRef.current.api?.deselectAll();
        changeCursor("doc-issuance-table", getShowMoveCursor());
    }, [getShowMoveCursor, onTableSelectionClicked])

    useEffect(() => {
        const columnDefs = (INITIAL_DOCUMENT_ISSUANCE_COL_DEF.slice());

        //gridRef.current?.gridRef.current.api?.setColumnDefs(columnDefs);
        if (!onTableSelectionClicked) {
            gridRef.current?.gridRef.current.api?.deselectAll();
        }
    })

    const handleRowDoubleClick = useCallback((entity: DocumentIssuanceEntity) => {
        documentIssuanceVM.onRowDoubleClick(entity);
    }, [documentIssuanceVM])

    useEffect(() => {
        const handleRightClickMenu = (event: any) => {
            if (_.isEmpty(documentIssuanceState.selectedRows) || !allowUpdate) {
                return;
            }
            event.preventDefault();
            rightClickRef.current.show(event);
        };
        document.getElementById('myDiv')?.addEventListener("contextmenu", handleRightClickMenu);

        return () => {
            document.getElementById('myDiv')?.removeEventListener("contextmenu", handleRightClickMenu);
        };
    }, [rightClickRef, documentIssuanceState.selectedRows, allowUpdate]);

    const getAllRows = useCallback(() => {
        return allRows;
    }, [allRows]);

    const getSelectedRows = useCallback(() => {
        return documentIssuanceState.selectedRows;
    }, [documentIssuanceState.selectedRows]);

    // for right click menu with move after begin
    const onRefreshRow = useCallback((newAllRows: any[]) => {
        setAllRows(newAllRows);
        documentIssuanceVM.updateSelectedRows([]);
        setShowMoveCursor(true);
    }, [documentIssuanceVM]);

    const onMoveCancel = useCallback(() => {
        setInitialAllRows(true);
        setAllRows(documentIssuanceState.tableData);
        documentIssuanceVM.updateSelectedRows([]);
        setShowMoveCursor(false);
    }, [documentIssuanceState.tableData, documentIssuanceVM]);

    const onSaveMove = useCallback(async (newAllRows: any[]) => {
        if (_.isEmpty(newAllRows)) return;

        setIsLoading(true);

        documentIssuanceVM.onApply(newAllRows).then((res) => {
            if (!res || !res.success) {
                messageBarVM.showWarining('Move failed.');
                setInitialAllRows(true);
                setIsLoading(false);

                documentIssuanceVM.updateSelectedRows([]);
                setShowMoveCursor(false);
            } else {
                documentIssuanceVM.searchDocIssuance().then((data) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    documentIssuanceVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                }).catch((e) => {
                    setInitialAllRows(true);
                    setIsLoading(false);

                    documentIssuanceVM.updateSelectedRows([]);
                    setShowMoveCursor(false);
                })
            }
        })
    }, [documentIssuanceVM, messageBarVM]);

    const handleAdd = useCallback(() => {
        documentIssuanceVM.onAddClick();
    }, [documentIssuanceVM]);

    const memoDocIssuanceTable = useMemo(() => {
        return (
            <>
                {(allowUpdate) && <RightClickMenuWithMoveAfter disabled={_.isEmpty(getSelectedRows())}
                    rightClickRef={rightClickRef} selectedRows={getSelectedRows()} allRows={getAllRows()}
                    onSaveMove={onSaveMove} onCancel={onMoveCancel} onRefreshRow={onRefreshRow}
                    showConfirmMove={showMoveCursor} priorityName="priority" />}

                <div id="myDiv">

                    <NbisTable
                        id='doc-issuance-table'
                        isNewColumnSetting={true}
                        headerLabel={DocumentIssuanceConstant.TITLE}
                        columns={INITIAL_DOCUMENT_ISSUANCE_COL_DEF}
                        data={transferRowData(allRows) ?? []}
                        showPaginator={false}
                        editable={false}
                        showAddIcon={true}
                        showDeleteButton={false}
                        showReloadIcon={false}
                        isScrollHighlighted={true}
                        selectionMode={false}
                        isRowHighligted={true}
                        onSelectionChanged={handleSelectionChange}
                        onRowDoubleClick={(e: any, entity: DocumentIssuanceEntity) => handleRowDoubleClick(entity)}
                        gridHeight="customHeight"
                        customHeight="calc(100vh - 75px)"
                        ref={gridRef}
                        rowSelection={showMoveCursor ? "single" : "multiple"}
                        onBodyScroll={(e: any) => changeCursor("doc-issuance-table", getShowMoveCursor())}
                        onAddClick={handleAdd}
                    />
                </div>
            </>
        );
    }, [allRows, allowUpdate, getAllRows, getSelectedRows, getShowMoveCursor, handleRowDoubleClick, handleSelectionChange, onMoveCancel, onRefreshRow, onSaveMove, rightClickRef, showMoveCursor, handleAdd])

    useEffect(() => {
        if (initialAllRows && documentIssuanceState.tableData && !_.isEmpty(documentIssuanceState.tableData)) {
            setAllRows(documentIssuanceState.tableData.map((docIssuanceEntity, index) => ({
                ...docIssuanceEntity,
                index: index ?? 0
            })));
            setInitialAllRows(false);
        }
    }, [allRows, initialAllRows, documentIssuanceState.tableData]);

    return <><TableWrapper>
        {(isLoading) && <Loader Indicator="Spinner" size="Medium" />}
        {memoDocIssuanceTable}
    </TableWrapper>
    </>;
}

export default memo(DocumentIssuanceTablePanel);
