import { ReportEntity } from "domain/entity/Report/ReportEntity";
import { axiosGetData } from "../axios/AxiosBasicImpl";
import referenceAxiosInstance from "../axios/referenceAxiosInstance";
import { ReportRepository } from "./ReportRepo";

export const ReportRepoImpl = (): ReportRepository => {
    
    const url = "/v1/report"

    const getReport = async() : Promise<ReportEntity[]> => {
        const newUrl = url + "/getReport";

        return axiosGetData(referenceAxiosInstance,`${newUrl}`,[]).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    

    return {
        getReport : getReport,
    }
}
