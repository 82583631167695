import { CompanyEntity } from "../Company/CompanyEntity"
import { CustomerEntity } from "../Company/CustomerEntity"
import { ReportEntity } from "../Report/ReportEntity"
import { DocumentSupportingEntity } from "./DocumentSupportingEntity"

export interface DocumentIssuanceEntity{
    
    id: number|null,
    
    priority: number|null,
    
    billToCompanyId: number|null,

    billToCompany: CompanyEntity | null,
    
    customerId?: number|null,

    customer: CustomerEntity | null,
    
    chargeType?: string|null,    
    
    issueType?: string|null,
    
    docType?: string|null,
    
    noOfCopy?: number|null,
    
    noOfSupportingDoc?: number|null,
    
    noOfOriginal?: number|null,
    
    asteriskLabel?: string|null,

    docLanguage?: string|null,
    
    printXXXDoc?: string|null,
    
    amountPrinting?: string|null,
    
    displayBillingMsg?: string|null,
    
    display2ndCurrency?: string|null,
    
    activeInd?: string|null,
    
    copyTo?: string|null,
    
    emailAddress?: string|null,
    
    ediEmailAddress?: string|null,
    
    reportId?: string|null,

    report?: ReportEntity | null,
    
    invoiceCurrency?: string|null,

    supportingDetails?: DocumentSupportingEntity[] | null,

    [key: string]: string[] | string | boolean | number | Date | null | undefined | Object
}

export const EMPTY_DOCUMENT_ISSUANCE_ENTITY : DocumentIssuanceEntity = {
  
    id: null,
    
    priority: null,
    
    billToCompanyId: null,

    billToCompany: null,
    
    customerId: null,

    customer: null,
    
    chargeType: null,    
    
    issueType: null,
    
    docType: null,
    
    noOfCopy: null,
    
    noOfSupportingDoc: null,
    
    noOfOriginal: null,
    
    asteriskLabel: null,

    docLanguage: null,
    
    printXXXDoc: null,
    
    amountPrinting: null,
    
    displayBillingMsg: null,
    
    display2ndCurrency: null,
    
    activeInd: null,
    
    copyTo: null,
    
    emailAddress: null,
    
    ediEmailAddress: null,
    
    reportId: null,

    report: null,
    
    invoiceCurrency: null,

    supportingDetails: null
}