import { ReportEntity } from "../Report/ReportEntity"
import { DocumentIssuanceEntity } from "./DocumentIssuanceEntity"

export interface DocumentSupportingEntity{
    id: number|null,
    versionIdentifier: {
        version?: string
    },
    docIssuanceId: number | null,
    docIssuance: DocumentIssuanceEntity | null,    
    reportId: number | null,
    report: ReportEntity | null,
    activeInd: string | null,
    chargeType: string | null,
    detailPrinting: string | null,
    supportingDoc: string | null,

    [key: string]: string | boolean | number | null | undefined | Object
}

export const EMPTY_DOCUMENT_SUPPORTING_ENTITY : DocumentSupportingEntity = {
    id: null,
    versionIdentifier: {},
    docIssuanceId: null,
    reportId: null,
    activeInd: "Y",
    chargeType: null,
    detailPrinting: null,
    docIssuance: null,
    report: null,
    supportingDoc: null,
}