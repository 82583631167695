
import { SliderPanel } from "veronica-ui-component/dist/component/core";
import DocumentIssuanceMaintenance from "./DocumentIssuanceMaintenance";
import { DocumentIssuanceHeaderFormPanel } from "./Form/DocumentIssuanceHeaderFormPanel";
import { DocumentIssuanceEditPanel } from "./DocumentIssuanceEditPanel";
import { useDocumentIssuanceTracked } from "presentation/store/DocumentIssuance/DocumentIssuanceProvider";


export const DocumentIssuanceMasterView: React.FC = () => {
    const [docIssuanceState] = useDocumentIssuanceTracked();

    const { isAdd, isEditable, isSliderOpen } = docIssuanceState.masterState;
    
    return <>
        <div className="main-comp-wrapper">            
            <DocumentIssuanceMaintenance/>            
            {
                ((isAdd || isEditable) &&
                <SliderPanel
                    isOpen={isSliderOpen}
                    leftSectionWidth={"37%"}
                    rightSectionWidth={"63%"}
                    leftChildren={<DocumentIssuanceHeaderFormPanel/>}
                    rightChildren={<DocumentIssuanceEditPanel/>}
                />
                )
            }
        </div>
    </>
}