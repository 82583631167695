
import { DocumentIssuanceEntity, EMPTY_DOCUMENT_ISSUANCE_ENTITY } from "domain/entity/Document/DocumentIssuanceEntity";
import { BaseViewChangeSate } from "../BaseViewChangeState";
import { DropdownProps } from "../DropdownProps";
import { DocumentSupportingEntity, EMPTY_DOCUMENT_SUPPORTING_ENTITY } from "domain/entity/Document/DocumentSupportingEntity";

export interface DocumentIssuanceDropdownOptions {
    billToCompanyDropdownOptions: DropdownProps[],
    issueTypeDropdownOptions: DropdownProps[],
    chargeTypeDropdownOptions: DropdownProps[],
    customerCodeDropdownOptions: DropdownProps[],
    docTypeDropdownOptions: DropdownProps[],
    languageDropdownOptions: DropdownProps[],
    amountPrintingDropdownOptions: DropdownProps[],
    reportTemplateDropdownOptions: DropdownProps[],
    invoiceCurrencyDropdownOptions: DropdownProps[],
    detailPrintingDropdownOptions: { [key: string]: DropdownProps[] },
    supportingDocDropdownOptions: { [key: string]: DropdownProps[] },
    indDropdownOptions: DropdownProps[],
}
export interface MasterChangeState extends BaseViewChangeSate {
    editingEntity: DocumentIssuanceEntity,
}

export interface DocumentSupportingChangeState extends BaseViewChangeSate {
    docSupportingList: DocumentSupportingEntity[],
    selectedRows: DocumentSupportingEntity[],
    currentRow: DocumentSupportingEntity
}

export interface DocumentIssuanceModel {
    isLoading: boolean,
    tableData: DocumentIssuanceEntity[],
    currentSelectedRow: DocumentIssuanceEntity,
    selectedRows: DocumentIssuanceEntity[],
    dynamicOptions: DocumentIssuanceDropdownOptions,
    masterState: MasterChangeState,
    detailState: DocumentSupportingChangeState,
    isBackMaster: boolean,
}

export const EMPTY_DOCUMENT_ISSUANCE_MODEL: DocumentIssuanceModel = {
    isLoading: false,
    tableData: [],
    currentSelectedRow: { ...EMPTY_DOCUMENT_ISSUANCE_ENTITY },
    selectedRows: [],
    dynamicOptions: {
        billToCompanyDropdownOptions: [],
        issueTypeDropdownOptions: [],
        chargeTypeDropdownOptions: [],
        customerCodeDropdownOptions: [],
        docTypeDropdownOptions: [],        
        languageDropdownOptions: [],
        amountPrintingDropdownOptions: [],
        reportTemplateDropdownOptions: [],
        invoiceCurrencyDropdownOptions: [],
        detailPrintingDropdownOptions: {},
        supportingDocDropdownOptions: {},
        indDropdownOptions: [],
    },
    masterState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        editingEntity: EMPTY_DOCUMENT_ISSUANCE_ENTITY,
    },
    detailState: {
        isSliderOpen: false,
        isTabularDataActive: false,
        isAdd: false,
        isRead: false,
        isEditable: false,
        isSaveClicked: false,
        lastEditRowId: "",
        isSelected: false,
        slider: {
            isAdd: false,
            isRead: false,
            isEditable: false
        },
        docSupportingList: [],
        selectedRows: [],
        currentRow: EMPTY_DOCUMENT_SUPPORTING_ENTITY
    },
    isBackMaster: false,
}